import React, { useEffect } from 'react'
import { ContactContainer } from './ContactElements'

export default function ContactPage () {

    return (
        <ContactContainer>
            <div class={'mt-5 text-center w-full'}>knoxcapitalfl@gmail.com</div>
        </ContactContainer>
    )
}